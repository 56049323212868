import React from 'react';
import { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  Button,
  Container,
  Grid,
  Box,
  Typography,
  Paper,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet';

import {
  theme,
  ResponsiveImage,
  StyledContainer,
  ParallaxBox,
} from './components/styles';

import NavigationBar from './NavigationBar';
import ContactForm from './ContactForm';
import Footer from './Footer';
import api from './components/api';

import backgroundImage from './images/home背景.png';
import aboutusImage from './images/aboutus.jpg';
import productImage from './images/Cycles_render-1.png';

interface ArticleMetadata {
  title: string;
  date: string;
  slug: string;
}

const HomePage = () => {
  const { t, i18n } = useTranslation();

  const pageTitle = t('Microscope for Lab Automation｜embrio');
  const pageDescription = t('We support bio-research with a microscope for lab automation.\nThrough simple API control, robot integration, and seamless data management, we accommodate any protocol for you.');
  const siteUrl = 'https://www.embrio.dev/';

  const [articles, setArticles] = useState<ArticleMetadata[]>([]);

  const generatePath = (path: string) => `/${i18n.language}${path}`;

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await api.get(`/api/news?lang=${i18n.language}`);
        setArticles(response.data.slice(0, 3));
      } catch (error) {
        console.error("Fetching articles failed", error);
      }
    };

    fetchArticles();
  }, [i18n.language]);

  return (
    <ThemeProvider theme={theme}>

      <Helmet>
        <link rel="canonical" href="https://www.embrio.dev/" />

        {/* Basic SEO */}
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="icon" href="./images/embrio_logo_square.png" />
        {/* <link rel="icon" type="image/png" href="./images/embrio_logo_square.png" sizes="16x16" /> */}

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        {/* <meta property="og:image" content={imageUrl} /> */}

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={siteUrl} />
        <meta property="twitter:title" content={pageTitle} />
        <meta property="twitter:description" content={pageDescription} />
        {/* <meta property="twitter:image" content={imageUrl} /> */}
      </Helmet>

      <NavigationBar />
      <ParallaxBox
        image={backgroundImage}
        overlayColor="rgba(0, 0, 0, 0.5)"
        paddingTop={10}
        paddingBottom={10}
      >
        <Typography variant="h1" color="white" textAlign="center" mt={10} mb={2}>
          embrio
        </Typography>
        <Typography variant="h5" color="white" textAlign="center" mb={12}>
          {t('Automate Your Lab with 3 Lines of Code')}
        </Typography>
      </ParallaxBox>
      <StyledContainer maxWidth="md" style={{ maxWidth: '90%', height: 'auto' }}>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          {/* About Us Section */}
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              Who We Are
            </Typography>
            <Typography paragraph>
              {t('Our expertise in hardware and software engineering, coupled with extensive experience in bio-research, empowers research institutions to embrace lab automation.')}
            </Typography>
            <Button variant="contained" color="primary" href={generatePath("/aboutus")}>
              {t('Learn More')}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <ResponsiveImage src={aboutusImage} alt="AboutUs Image" />
          </Grid>

          {/* Products Section */}
          <Grid item xs={12} md={6}>
            <ResponsiveImage src={productImage} alt="Product Image" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              What We Do
            </Typography>
            <Typography paragraph>
              {t('We develop hardware and software solutions for lab automation.')}<br />{t('We develop lab automation equipment including microscope and plate handler that are Web API-controllable, and also develop lab automation software and image analysis models to support companies and researchers in automating their laboratories.')}<br />{t('We also assist manufacturers in smartening and IoT-enabling their experimental equipment.')}
            </Typography>
            <Button variant="contained" color="primary" href={generatePath("/business")}>
              {t('Learn More')}
            </Button>
          </Grid>
        </Grid>
      </StyledContainer>

      <Container maxWidth="md">
        <Box my={5}>
          <Typography variant="h4" gutterBottom sx={{ mb: 4, textAlign: 'center' }}>
            {t('Latest News')}
          </Typography>
          {articles.map((article) => (
            <Paper
              key={article.slug}
              elevation={0}
              sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                mb: 3,
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Typography
                  variant="h6"
                  component={RouterLink}
                  to={`/${i18n.language}/news/${article.slug}`}
                  sx={{
                    textDecoration: 'none',
                    color: theme.palette.secondary.main,
                    // color: "black",
                    ':hover': { textDecoration: 'underline' },
                  }}
                >
                  {article.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
                  {new Date(article.date).toLocaleDateString()}
                </Typography>
              </Box>
            </Paper>
          ))}
          <Box textAlign="center" mt={4}>
            <Button
              variant="text"
              color="secondary"
              component={RouterLink}
              to={generatePath("/news")}
            >
              {t('More News')}
            </Button>
          </Box>
        </Box>
      </Container>

      <ContactForm />
      <Footer />
    </ThemeProvider>
  );
};

export default HomePage;
